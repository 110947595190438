import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import {
  createSalesRecord,
  updateSalesRecord,
} from "../../services/SaleService";
import { listProducts } from "../../services/ProductService";
import CustomerForm from "../Customer/CustomerForm";
import { listCustomer } from "../../services/CustomerService";
import * as yup from 'yup';

// TODO: Validation Schema
const validationSchema = yup.object().shape({
  customerEmail: yup.string().email("Invalid email address").notRequired()
})

const SalesRecordForm = ({
  open,
  handleClose,
  onRecordAdded,
  onRecordEdited,
  editRecord,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const editMode = Boolean(editRecord);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await listProducts();
      setProducts(response.data);
    };

    const fetchCustomers = async () => {
      // Fetch customers to populate the customer dropdown
      const response = await listCustomer(); // Assuming you have a getCustomers API
      setCustomers(response.data);
    };

    fetchProducts();
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (editMode && editRecord) {
        // Prepopulate selectedProduct if productId exists in the editRecord
        const product = products.find((p) => p.id === editRecord.productId) || null;
        setSelectedProduct(product);
    }
}, [editMode, editRecord, products]);

  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false);
  };
  
  const handleCustomerAdded = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
    setSelectedCustomerId(newCustomer.id);
    setOpenCustomerModal(false);
  };

  const initialValues = {
    title: editRecord?.title || "",
    type: editRecord?.type || "",
    quantity: editRecord?.quantity || "",
    rate: editRecord?.rate || "",
    salesDesc: editRecord?.salesDesc || "",
    customerName: editRecord?.customerName || "",
    customerEmail: editRecord?.customerEmail || "",
    date: editRecord?.date || "",
    productId: editRecord?.productId || "",
    paymentStatus: editRecord?.paymentStatus || "PENDING_PAYMENT",
    amountPaid: editRecord?.amountPaid || "",
    outstandingBalance: editRecord?.outstandingBalance || 0,
    installmentPlan: editRecord?.installmentPlan || "INACTIVE",
    customerId: selectedCustomerId || (editRecord ? editRecord.customerId : null),
    
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (!selectedProduct) {
        console.error("No product selected. Please select a product.");
        return; // Prevent submission
      }

      console.log("Selected Product: ", selectedProduct);
      const salesData = {
        ...values,
        productId: selectedProduct ? selectedProduct.id : null,
        paymentStatus: values.paymentStatus,
        outstandingBalance: values.outstandingBalance,
        installmentPlan: values.installmentPlan,
        customerId: selectedCustomerId,
      };

      console.log("Submitting sales data:", salesData);
      
      if (editMode) {
        const response = await updateSalesRecord(editRecord.id, salesData);
        onRecordEdited(response.data);
      } else {
        const response = await createSalesRecord(salesData);
        onRecordAdded(response.data);
      }
      handleClose();
    } catch (error) {
      console.error(
        `Failed to ${editMode ? "update" : "create"} sales record: `,
        error
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle textAlign="center" variant="h3" margin="10px 0">
          {editMode ? "Edit Sales Record" : "Add New Sales Record"}
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  gridColumn="span 4"
                >
                  {/* Product/Expense Title with Searchable Input */}
                  <Autocomplete
                      freeSolo
                      options={products.map((product) => product.productName)}
                      value={values.title}
                      onChange={(event, newValue) => {
                          setFieldValue("title", newValue);

                          // Find the product by name and update selectedProduct
                          const matchedProduct = products.find((product) => product.productName === newValue);
                          if (matchedProduct) {
                              setFieldValue("productId", matchedProduct.id);
                              setSelectedProduct(matchedProduct); // Update state with the full product object
                          } else {
                              setFieldValue("productId", null);
                              setSelectedProduct(null); // Clear selectedProduct if no match
                          }
                      }}
                      onInputChange={(event, newInputValue) => {
                          setFieldValue("title", newInputValue);

                          // Optional: Clear productId if the input doesn't match an existing product
                          if (!products.some((product) => product.productName === newInputValue)) {
                              setFieldValue("productId", null);
                              setSelectedProduct(null); // Clear selectedProduct for custom inputs
                          }
                      }}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Product Title"
                              variant="filled"
                              name="title"
                              onBlur={handleBlur}
                              error={!!touched.title && !!errors.title}
                              helperText={touched.title && errors.title}
                              sx={{
                                  gridColumn: "span 2",
                                  '.MuiInputBase-root': {
                                      paddingTop: "12px",
                                      paddingBottom: "12px",
                                  },
                              }}
                              inputProps={{
                                  ...params.inputProps,
                                  style: {
                                      marginTop: "4px",
                                      padding: "10px"
                                  },
                              }}
                          />
                      )}
                      sx={{
                          gridColumn: "span 2",
                      }}
                  />

                  <Autocomplete
                      freeSolo // Enables manual input
                      options={["Cement", "Building Blocks"]} // Predefined options
                      value={values.type}
                      onChange={(event, newValue) => setFieldValue("type", newValue)}
                      onInputChange={(event, newInputValue) => setFieldValue("type", newInputValue)} 
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="filled"
                              label="Type"
                              name="type"
                              onBlur={handleBlur}
                              error={!!touched.type && !!errors.type}
                              helperText={touched.type && errors.type}
                              sx={{
                                  gridColumn: "span 2",
                                  '.MuiInputBase-root': {
                                      paddingTop: "12px",
                                      paddingBottom: "12px",
                                  },
                              }}
                              inputProps={{
                                  ...params.inputProps,
                                  style: {
                                      marginTop: "4px",
                                      padding: "10px"
                                  },
                              }}
                          />
                      )}
                      sx={{
                          gridColumn: "span 2",
                      }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    min="0"
                    label="Quantity"
                    name="quantity"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const quantity = parseFloat(event.target.value || 0);
                      setFieldValue("quantity", quantity);
                    
                      const rate = values.rate || 0; // Fallback for undefined rate
                      const totalBalance = rate * quantity;
                      const amountPaid = values.amountPaid || 0; // Fallback for undefined amount paid
                    
                      // Adjust based on payment status
                      if (values.paymentStatus === "PENDING_PAYMENT") {
                        setFieldValue("outstandingBalance", totalBalance); // Full balance for pending payment
                        setFieldValue("amountPaid", "");
                      } else if (values.paymentStatus === "PARTIAL_PAYMENT") {
                        setFieldValue("outstandingBalance", totalBalance - amountPaid); // Adjust for partial payments
                      } else if (values.paymentStatus === "FULLY_PAID") {
                        setFieldValue("outstandingBalance", 0); // No outstanding balance for fully paid
                        setFieldValue("amountPaid", totalBalance); // Amount paid equals total balance
                      }
                    }}                                        
                    value={values.quantity}
                    error={!!touched.quantity && !!errors.quantity}
                    helperText={touched.quantity && errors.quantity}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                        alignItems: "center",
                      },
                    }}
                  />

                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="customer-select-label">Customer Name</InputLabel>
                    <Select
                      labelId="customer-select-label"
                      value={values.customerName}
                      sx={{
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        if (selectedValue === "add-new") {
                          setOpenCustomerModal(true);
                        } else {
                          const selectedCustomer = customers.find((customer) => customer.fullName === selectedValue);
                          setFieldValue("customerName", selectedCustomer ? selectedCustomer.fullName : "");
                          setFieldValue("customerEmail", selectedCustomer && selectedCustomer.email ? selectedCustomer.email : "");
                          setSelectedCustomerId(selectedCustomer ? selectedCustomer.id : null); // Set customerId dynamically
                        }
                      }}
                      onBlur={handleBlur}
                    >
                      {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.fullName}>
                          {customer.fullName}
                        </MenuItem>
                      ))}
                      <MenuItem value="add-new" style={{ color: colors.blueAccent[300] }}>
                        Add New Customer
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    name="salesDesc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.salesDesc}
                    error={!!touched.salesDesc && !!errors.salesDesc}
                    helperText={touched.salesDesc && errors.salesDesc}
                    sx={{ gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Customer Email"
                    name="customerEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerEmail}
                    error={!!touched.customerEmail && !!errors.customerEmail}
                    helperText={touched.customerEmail && errors.customerEmail}
                    sx={{ gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Rate"
                    name="rate"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newRate = parseFloat(event.target.value || 0);
                      setFieldValue("rate", newRate);
                    
                      const newTotalBalance = newRate * (values.quantity || 0); // Handle zero quantity
                      const amountPaid = values.amountPaid || 0; // Handle undefined amount paid
                    
                      if (values.paymentStatus === "PENDING_PAYMENT") {
                        setFieldValue("outstandingBalance", newTotalBalance); // Full balance for pending payment
                      } else if (values.paymentStatus === "PARTIAL_PAYMENT") {
                        setFieldValue("outstandingBalance", newTotalBalance - amountPaid); // Adjust for partial payments
                      } else if (values.paymentStatus === "FULLY_PAID") {
                        setFieldValue("outstandingBalance", 0); // Fully paid has no outstanding balance
                        setFieldValue("amountPaid", newTotalBalance); // Amount paid equals total balance
                      }
                    }}                    
                    value={values.rate}
                    error={!!touched.rate && !!errors.rate}
                    helperText={touched.rate && errors.rate}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />


                  <TextField
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date"
                    name="date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date}
                    error={!!touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                    sx={{
                      gridColumn: "span 2",
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />

                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="payment-status-label">Payment Status</InputLabel>
                    <Select
                      labelId="payment-status-label"
                      name="paymentStatus"
                      value={values.paymentStatus}
                      onChange={(event) => {
                        const paymentStatus = event.target.value;
                        setFieldValue("paymentStatus", paymentStatus);
                      
                        const totalBalance = (values.rate || 0) * (values.quantity || 0); // Handle missing values
                      
                        if (paymentStatus === "PENDING_PAYMENT") {
                          setFieldValue("outstandingBalance", totalBalance); // Full balance for pending payment
                          setFieldValue("amountPaid", "");
                          setFieldValue("installmentPlan", "INACTIVE");
                        } else if (paymentStatus === "PARTIAL_PAYMENT") {
                          setFieldValue("amountPaid", ""); // Allow user to input partial payment
                          setFieldValue("outstandingBalance", totalBalance); // Show the outstanding balance
                          setFieldValue("installmentPlan", "ACTIVE");
                        } else if (paymentStatus === "FULLY_PAID") {
                          setFieldValue("amountPaid", totalBalance); // Amount paid equals total balance
                          setFieldValue("outstandingBalance", 0); // Fully paid has no outstanding balance
                          setFieldValue("installmentPlan", "COMPLETED");
                        }
                      }}                                      
                      onBlur={handleBlur}
                      sx={{
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <MenuItem value="PENDING_PAYMENT">Pending Payment</MenuItem>
                      <MenuItem value="PARTIAL_PAYMENT">Partial Payment (Installment)</MenuItem>
                      <MenuItem value="FULLY_PAID">Fully Paid</MenuItem>
                    </Select>

                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Total Balance"
                    name="totalBalance"
                    value={(values.quantity || 0) * (values.rate || 0)} // Dynamically calculates total price
                    inputProps={{
                      readOnly: true, // Makes the field non-editable
                    }}
                    sx={{
                      gridColumn: "span 2",
                    }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "7.5px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Amount Paid"
                    name="amountPaid"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const amountPaid = parseFloat(event.target.value || 0); // Default to 0 if no value
                      setFieldValue("amountPaid", amountPaid);
                    
                      if (values.paymentStatus === "PARTIAL_PAYMENT") {
                        const totalBalance = (values.rate || 0) * (values.quantity || 0); // Use values.rate
                        setFieldValue("outstandingBalance", totalBalance - amountPaid);
                      }
                    }}                    
                    value={values.amountPaid}
                    error={!!touched.amountPaid && !!errors.amountPaid}
                    helperText={touched.amountPaid && errors.amountPaid}
                    disabled={values.paymentStatus !== "PARTIAL_PAYMENT"}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Outstanding Balance"
                    name="outstandingBalance"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const selectedProductId = event.target.value;
                      const product = products.find(p => p.id === selectedProductId);
                    
                      if (product) {
                        setSelectedProduct(product);
                        setFieldValue("type", product.type); // Set product type
                    
                        // Keep the rate unchanged; rely on values.rate
                        const rate = values.rate || 0; // Default to 0 if rate is unset
                        const quantity = values.quantity || 0;
                        const totalBalance = rate * quantity;
                        const amountPaid = values.amountPaid || 0;
                    
                        // Update Outstanding Balance based on Payment Status
                        if (values.paymentStatus === "PENDING_PAYMENT") {
                          setFieldValue("outstandingBalance", totalBalance);
                          setFieldValue("amountPaid", 0);
                        } else if (values.paymentStatus === "PARTIAL_PAYMENT") {
                          setFieldValue("outstandingBalance", totalBalance - amountPaid);
                        } else if (values.paymentStatus === "FULLY_PAID") {
                          setFieldValue("outstandingBalance", 0);
                          setFieldValue("amountPaid", totalBalance);
                        }
                      }
                    }}                                                           
                    value={values.outstandingBalance}
                    error={!!touched.outstandingBalance && !!errors.outstandingBalance}
                    helperText={touched.outstandingBalance && errors.outstandingBalance}
                    disabled={values.paymentStatus === "FULLY_PAID"}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "10px 0",
                      },
                    }}
                  />

                  {/* <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="installment-plan-label">Installment Plan Status</InputLabel>
                    <Select
                      labelId="installment-plan-label"
                      name="installmentPlan"
                      value={values.installmentPlan}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        height: "100%", // Ensure it takes the full height of the FormControl
                        display: 'flex', // Align vertically
                        alignItems: 'center', // Center align vertically
                      }}
                    >
                      <MenuItem value="INACTIVE">Inactive</MenuItem>
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="COMPLETED">Completed</MenuItem>
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", height: "68px" }}>
                    <InputLabel id="payment-frequency-label">Payment Frequency</InputLabel>
                    <Select
                      labelId="payment-frequency-label"
                      name="paymentFrequency"
                      value={values.paymentFrequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                      <MenuItem value="BIWEEKLY">Twice a Month</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                      <MenuItem value="QUARTERLY">Every 3 Months</MenuItem>
                    </Select>
                  </FormControl>


                  <TextField
                    fullWidth
                    select
                    variant="filled"
                    label="Installment Plan Duration"
                    name="installmentDuration"
                    value={values.installmentDuration}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== "custom") {
                        setFieldValue("customInstallmentDuration", ""); // Reset custom value
                      }
                    }}
                    onBlur={handleBlur}
                    error={!!touched.installmentDuration && !!errors.installmentDuration}
                    helperText={touched.installmentDuration && errors.installmentDuration}
                    sx={{ marginBottom: "4px", gridColumn: "span 2" }}
                    InputLabelProps={{
                      style: {
                        marginTop: "4px",
                      },
                    }}
                    InputProps={{
                      style: {
                        padding: "8px 0",
                      },
                    }}
                    SelectProps={{
                      renderValue: (selected) =>
                        selected === "custom"
                          ? values.customInstallmentDuration || "Custom"
                          : selected,
                    }}
                  >
                    {/* Updated predefined options */}
                    <MenuItem value="1 Month">1 Month</MenuItem>
                    <MenuItem value="3 Months">3 Months</MenuItem>
                    <MenuItem value="6 Months">6 Months</MenuItem>
                    <MenuItem value="1 Year">1 Year</MenuItem>
                    <MenuItem value="Custom">Other (Custom)</MenuItem>
                  </TextField>

                  {/* Custom input overlay */}
                  {values.installmentDuration === "Custom" && (
                    <TextField
                      fullWidth
                      variant="filled"
                      label="Enter Custom Duration (in days)"
                      name="customInstallmentDuration"
                      value={values.customInstallmentDuration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!touched.customInstallmentDuration && !!errors.customInstallmentDuration}
                      helperText={touched.customInstallmentDuration && errors.customInstallmentDuration}
                      sx={{ gridColumn: "span 4" }}
                      InputLabelProps={{
                        style: {
                          marginTop: "4px",
                        },
                      }}
                      InputProps={{
                        style: {
                          padding: "10px 0",
                        },
                      }}
                    />
                  )}
                </Box>
                
                <DialogActions sx={{ mt: "20px", float: "right" }}>
                  <Button
                    onClick={handleClose}
                    sx={{ color: colors.redAccent[400], padding: "8px 12px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      color: colors.gray[100],
                      backgroundColor: colors.blueAccent[700],
                      padding: "12px 16px",
                    }}
                  >
                    {editMode ? "Update" : "Submit"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <CustomerForm
        open={openCustomerModal}
        handleClose={handleCloseCustomerModal}
        onCustomerAdded={handleCustomerAdded}
      />
    </>
    
    
  );
};

export default SalesRecordForm;

import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, MenuItem, Select, FormControl, InputLabel, Autocomplete } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import { createExpensesRecord, updateExpensesRecord } from "../../services/ExpenseService";
import { listProducts } from "../../services/ProductService";

// TODO: Validation Schema

const ExpensesRecordForm = ({ open, handleClose, onRecordAdded, onRecordEdited, editRecord }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const editMode = Boolean(editRecord);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
          const response = await listProducts();
          setProducts(response.data);
        };
        fetchProducts();
      }, []);

      useEffect(() => {
        if (editMode && editRecord) {
            // Prepopulate selectedProduct if productId exists in the editRecord
            const product = products.find((p) => p.id === editRecord.productId) || null;
            setSelectedProduct(product);
        }
    }, [editMode, editRecord, products]);
    

    const initialValues = {
        title: editRecord?.title || "",
        type: editRecord?.type || "",
        quantity: editRecord?.quantity || "",
        cost: editRecord?.cost || "",
        expensesDesc: editRecord?.expensesDesc || "",
        supplierName: editRecord?.supplierName || "N/A",
        date: editRecord?.date || "",
        productId: editRecord?.productId || "",
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        try {

            if (!selectedProduct) {
                console.error("No product selected. Please select a product.");
                return; // Prevent submission
            }

            console.log("Selected Product: ", selectedProduct);
            const expensesData = {
                ...values,
                productId: selectedProduct ? selectedProduct.id : null,
              };
       

              console.log("Submitting expenses data:", expensesData);

            if (editMode) {
                const response = await updateExpensesRecord(editRecord.id, expensesData);
                onRecordEdited(response.data)
            }
            else{
                const response = await createExpensesRecord(expensesData);
                onRecordAdded(response.data);
            }
            handleClose();
        } catch (error) {
            console.error(`Failed to ${editMode ? "update" : "create"} expenses record: `, error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth = "md"
            fullWidth
        >
                <DialogTitle textAlign="center" variant="h3" margin="10px 0">
                    {editMode ? "Edit Expenses Record" : "Add New Expenses Record"}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    gridColumn="span 4">

                                    {/* Product/Expense Title with Searchable Input */}
                                    <Autocomplete
                                        freeSolo
                                        options={products.map((product) => product.productName)}
                                        value={values.title}
                                        onChange={(event, newValue) => {
                                            setFieldValue("title", newValue);

                                            // Find the product by name and update selectedProduct
                                            const matchedProduct = products.find((product) => product.productName === newValue);
                                            if (matchedProduct) {
                                                setFieldValue("productId", matchedProduct.id);
                                                setSelectedProduct(matchedProduct); // Update state with the full product object
                                            } else {
                                                setFieldValue("productId", null);
                                                setSelectedProduct(null); // Clear selectedProduct if no match
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setFieldValue("title", newInputValue);

                                            // Optional: Clear productId if the input doesn't match an existing product
                                            if (!products.some((product) => product.productName === newInputValue)) {
                                                setFieldValue("productId", null);
                                                setSelectedProduct(null); // Clear selectedProduct for custom inputs
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Product/Expense Title"
                                                variant="filled"
                                                name="title"
                                                onBlur={handleBlur}
                                                error={!!touched.title && !!errors.title}
                                                helperText={touched.title && errors.title}
                                                sx={{
                                                    gridColumn: "span 2",
                                                    '.MuiInputBase-root': {
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                    },
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: {
                                                        marginTop: "4px",
                                                        padding: "10px"
                                                    },
                                                }}
                                            />
                                        )}
                                        sx={{
                                            gridColumn: "span 2",
                                        }}
                                    />


                                    
                                    <Autocomplete
                                        freeSolo // Enables manual input
                                        options={["Cement", "Building Blocks"]} // Predefined options
                                        value={values.type}
                                        onChange={(event, newValue) => setFieldValue("type", newValue)}
                                        onInputChange={(event, newInputValue) => setFieldValue("type", newInputValue)} 
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Type"
                                                name="type"
                                                onBlur={handleBlur}
                                                error={!!touched.type && !!errors.type}
                                                helperText={touched.type && errors.type}
                                                sx={{
                                                    gridColumn: "span 2",
                                                    '.MuiInputBase-root': {
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                    },
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: {
                                                        marginTop: "4px",
                                                        padding: "10px"
                                                    },
                                                }}
                                            />
                                        )}
                                        sx={{
                                            gridColumn: "span 2",
                                        }}
                                    />


                                    <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Description"
                                            name="expensesDesc"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expensesDesc}
                                            error={!!touched.expensesDesc && !!errors.expensesDesc}
                                            helperText={touched.expensesDesc && errors.expensesDesc}
                                            sx={{ gridColumn: "span 4"}}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            min="0"
                                            label="Quantity"
                                            name="quantity"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.quantity}
                                            error={!!touched.quantity && !!errors.quantity}
                                            helperText={touched.quantity && errors.quantity}
                                            sx={{ gridColumn: "span 2"}}
                                            inputProps={{ min: 0 }}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0",
                                                    alignItems: "center"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Supplier"
                                            name="supplierName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.supplierName}
                                            error={!!touched.supplierName && !!errors.supplierName}
                                            helperText={touched.supplierName && errors.supplierName}
                                            sx={{ gridColumn: "span 2"}}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "8px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="Cost"
                                            name="cost"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.cost}
                                            error={!!touched.cost && !!errors.cost}
                                            helperText={touched.cost && errors.cost}
                                            sx={{ gridColumn: "span 2"}}
                                            inputProps={{ min: 0 }}
                                            inputlabelprops={{
                                                style: { 
                                                    marginTop: '4px',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "10px 0"
                                                }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="date"
                                            label="Date"
                                            name="date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.date}
                                            error={!!touched.date && !!errors.date}
                                            helperText={touched.date && errors.date}
                                            sx={{
                                                 gridColumn: "span 2",
                                            }}
                                            InputProps={{
                                                style: {
                                                    padding: "10px 0"
                                                }
                                            }}
                                        />
                                    </Box>
                                    <DialogActions sx={{mt: "20px", float: "right"}}>
                                        <Button onClick={handleClose} sx={{color: colors.redAccent[400], padding: "8px 12px"}}>Cancel</Button>
                                        <Button type="submit" sx={{color: colors.gray[100], backgroundColor: colors.blueAccent[700], padding: "12px 16px"}}>{editMode ? "Update" : "Submit"}</Button>
                                    </DialogActions>
                            </form>
                        )}
                    </Formik>
                </DialogContent>  
            </Dialog>
    )
}

export default ExpensesRecordForm;
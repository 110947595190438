import React, { useState } from 'react';
import { Box, Typography, Button, Divider, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';

const SearchResults = ({ searchResults, query, userRole }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expandedCategories, setExpandedCategories] = useState({});
  const navigate = useNavigate();

  const handleSeeMoreClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category], // Toggle the expanded state
    }));
  };

  const handleResultClick = (category, item) => {
    const routes = {
      Sales: '/sales',
      Expenses: '/expenses',
      Customers: '/customers',
      Products: '/inventory',
      Users: '/users',
    };

    const route = routes[category];

    if (route) {
      navigate(route, { state: { id: item.id } });
    }
  };

  // Do not render if there's no query or no results
  if (!query || !searchResults?.categories) return null;

  return (
    <Box sx={{ padding: '12px', zIndex: 1000, borderRadius: '8px' }}>
      {searchResults.categories
        .filter((category) => 
          category.name !== 'Users' || userRole === 'ADMIN'
        )
        .map((category, idx) => (
          <Box key={category.name} sx={{ marginBottom: 2 }}>
            {/* Render Category Name */}
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              {category.name} ({category.totalResults})
            </Typography>

            {/* Render Results for Each Category */}
            {category.results
              .slice(0, expandedCategories[category.name] ? category.results.length : 3)
              .map((item, index) => (
                <Box
                  key={item.id || index}
                  sx={{
                    marginBottom: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: colors.primary[400], transition: 'all 0.3s ease-in-out' },
                  }}
                  onClick={() => handleResultClick(category.name, item)}
                >
                  <Typography sx={{ marginBottom: 0.5 }}>
                    {/* Adjust the display to match the API response structure */}
                    {category.name === 'Sales' && `${item.id} - ${item.customerName} - ${item.salesDesc}`}
                    {category.name === 'Customers' && `${item.id} - ${item.fullName} - ${item.phoneNumber}`}
                    {category.name === 'Expenses' && `${item.id} - ${item.supplierName} - ${item.expensesDesc}`}
                    {category.name === 'Products' && `${item.id} - ${item.productName} - ${item.category}`}
                    {category.name === 'Users' && `${item.id} - ${item.fullName} - ${item.email}`}
                  </Typography>
                  {index < category.results.length - 1 && <Divider sx={{ margin: '8px 0' }} />}
                </Box>
              ))}

            {/* "See More/See Less" toggle for categories */}
            {category.results.length > 3 && (
              <Button
                onClick={() => handleSeeMoreClick(category.name)}
                sx={{ textTransform: 'none', padding: 0 }}
              >
                {expandedCategories[category.name] ? 'See Less' : 'See More'}
              </Button>
            )}

            {/* Divider between categories */}
            {idx < searchResults.categories.length - 1 && <Divider sx={{ margin: '12px 0' }} />}
          </Box>
        ))}

      {/* Show a message if there are no results */}
      {searchResults.categories.length === 0 && (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          No results found.
        </Typography>
      )}
    </Box>
  );
};

export default SearchResults;


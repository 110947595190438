import { Box, IconButton, useTheme, Menu, MenuItem, Avatar, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import { getYourProfile, isAuthenticated, logout } from "../../services/UserService";
import { searchRecords } from "../../services/SearchService"; // Import the search service
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/Search/SearchBar";
import SearchResults from "../../components/Search/SearchResults"; // Import SearchResults

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const [user, setUser] = useState({ fullName: "", role: "" });
    const authenticated = isAuthenticated();

    const [searchResults, setSearchResults] = useState(null); // State to hold search results
    const [query, setQuery] = useState(""); // State for search query
    const searchResultsRef = useRef(null);
    const searchBarRef = useRef(null); // Reference for the search bar

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getYourProfile();
                if (response && response.data) {
                    setUser({
                        fullName: response.data.fullName,
                        role: response.data.role,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch user info:", error);
            }
        };

        if (authenticated) {
            fetchProfile();
        }
    }, [authenticated]);

    // Handle search and fetch results from API
    const handleSearch = async (searchQuery) => {
        setQuery(searchQuery); // Update query state
        try {
            const response = await searchRecords(searchQuery); // Call API with query
            console.log(response);
            console.log(response.data);
            
            
            if (response && response.data) {
                setSearchResults(response.data); // Set the API response to searchResults
            } else {
                setSearchResults([]); // Handle case where no results are returned
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
            setSearchResults([]); // Clear results on error
        }
    };

    // Close search results if clicked outside
    const handleClickOutside = (event) => {
        if (
            searchResultsRef.current &&
            !searchResultsRef.current.contains(event.target) &&
            !searchBarRef.current.contains(event.target)
        ) {
            setSearchResults(null); // Close the results if clicked outside
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener("click", handleClickOutside);

        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const openLogoutDialog = () => {
        setIsDialogOpen(true);
    };

    const closeLogoutDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirmLogout = () => {
        logout(); // Ensure this function clears the token and user state
        handleProfileMenuClose();
        navigate("/login");
    };

    const handleUserProfile = () => {
        handleProfileMenuClose();
        navigate("/profile");
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1, position: "relative" }}>
                <SearchBar onSearch={handleSearch} ref={searchBarRef} />

                {/* Display search results directly below the search bar */}
                {searchResults && query && (
                    <Box
                        ref={searchResultsRef}
                        sx={{
                            position: "absolute",
                            top: "100%", // Positions it directly below the search bar
                            left: 0,
                            width: "250px", // Set width to match search box
                            height: "300px",
                            overflowY: "auto",
                            backgroundColor: colors.primary[500],
                            boxShadow: 3,
                            zIndex: 100,
                            borderRadius: "4px",
                        }}
                    >
                        <SearchResults searchResults={searchResults} query={query} userRole={user.role}/>

                    </Box>
                )}
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon sx={{ fontSize: "20px", mr: "8px" }} />
                    ) : (
                        <LightModeOutlinedIcon sx={{ fontSize: "20px", mr: "8px" }} />
                    )}
                    <Typography variant="h6">Appearance</Typography>
                </IconButton>
                {authenticated && (
                    <IconButton onClick={handleProfileMenuOpen} sx={{ ml: "16px" }}>
                        <PersonOutlinedIcon sx={{ fontSize: "20px", mr: "8px" }} />
                        <Typography variant="h6">Profile</Typography>
                    </IconButton>
                )}
            </Box>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose}>
                <MenuItem>
                    <Avatar>{user.fullName.charAt(0)}</Avatar>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body1">{user.fullName}</Typography>
                        <Typography variant="body2" marginTop="5px" color={colors.greenAccent[600]}>
                            {user.role}
                        </Typography>
                    </Box>
                </MenuItem>
                {authenticated && (
                    <MenuItem onClick={handleUserProfile}>
                        <PersonOutlinedIcon sx={{ mr: "10px" }} /> User Profile
                    </MenuItem>
                )}
                {authenticated && (
                    <MenuItem onClick={openLogoutDialog}>
                        <LogoutOutlinedIcon sx={{ mr: "10px" }} /> Log out
                    </MenuItem>
                )}
            </Menu>

            <Dialog
                open={isDialogOpen}
                onClose={closeLogoutDialog}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogTitle id="logout-dialog-title" textAlign="center">
                    <Typography fontWeight="bold">Confirm Logout</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeLogoutDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirmLogout();
                            closeLogoutDialog();
                        }}
                        color="secondary"
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Topbar;

import axios from "axios";

const REST_API_BASE_URL = "https://seashell-app-ao54r.ondigitalocean.app/api/search";

const token = localStorage.getItem('token');

export const searchRecords = (query, limit = 10) => {
    return axios.get(REST_API_BASE_URL, {
        params: {
            query: query,
            limit: limit
        },
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};

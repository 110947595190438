import { Box, Button, useTheme  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Header from "../../components/Header";
import {
  listCustomer,
  getCustomer,
  deleteCustomer,
  deleteAllCustomers,
} from "../../services/CustomerService";
import CustomerForm from "../../components/Customer/CustomerForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CustomerDeleteDialog from "../../components/Customer/CustomerDeleteDialog";
import { isAdmin } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import  VisibilityOutlinedIcon  from "@mui/icons-material/VisibilityOutlined";
import { useLocation } from "react-router-dom";

const Customers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [removeCustomer, setRemoveCustomer] = useState(null);
  const [isBulkDelete, setIsBulkDelete] = useState(false);

  const location = useLocation();
  const [highlightedRowId, setHighlightedRowId] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);

  const admin = isAdmin();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCustomer(null);
  };

  const handleOpenDialog = (id = null) => {
    setRemoveCustomer(id);
    setIsBulkDelete(id === null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    listCustomer()
      .then((response) => {
        const data = response.data.map((item) => ({
          id: item.id,
          ...item,
        }));
        setRows(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    // Check if there is an `id` in the navigation state
    if (location.state && location.state.id) {
      const targetId = location.state.id;
      setHighlightedRowId(targetId); // Set the row to be highlighted
  
      // Scroll to the row in the table
      setTimeout(() => {
        document.querySelector(`[data-id="${targetId}"]`)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500); // Delay to ensure DataGrid is rendered
    }
  }, [location]);

  useEffect(() => {
    if (highlightedRowId) {
      const fadeTimeout = setTimeout(() => {
        setFadeOut(true); // Trigger fade-out
        setTimeout(() => {
          setHighlightedRowId(null); // Clear highlight
          setFadeOut(false); // Reset fade-out
        }, 300); // Match transition duration
      }, 2500); // Delay before starting fade-out
  
      return () => clearTimeout(fadeTimeout); // Cleanup timeout
    }
  }, [highlightedRowId]);
  
  const getRowClassName = (params) => {
    if (params.id === highlightedRowId) {
      return fadeOut ? "highlighted-row fade-out" : "highlighted-row";
    }
    return "";
  };
  
  const handleAddCustomer = (newCustomer) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newCustomer];

      return updatedRows;
    });
  };

  const handleEditCustomer = (row) => {
    getCustomer(row.id)
      .then((response) => {
        setEditCustomer(response.data);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching customer details by ID: ", error);
      });
  };

  const handleDeleteCustomer = () => {
    if (isBulkDelete) {
      deleteAllCustomers()
        .then(() => {
          setRows([]);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.error("Error deleting all customer details: ", error);
        });
    } else if (removeCustomer) {
      deleteCustomer(removeCustomer)
        .then(() => {
          setRows((prevRows) =>
            prevRows.filter((row) => row.id !== removeCustomer)
          );
          setRemoveCustomer(null);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.error("Error deleting customer: ", error);
        });
    }
  };

  const handleCustomerEdited = (updatedCustomer) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((customerDetails) =>
        customerDetails.id === updatedCustomer.id
          ? { ...customerDetails, ...updatedCustomer }
          : customerDetails
      );

      return updatedRows;
    });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.25 },
    { field: "fullName", headerName: "Full Name", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 0.5 },
    { field: "storeAddress", headerName: "Store Address", flex: 0.75 },
    { field: "city", headerName: "City", flex: 0.25 },
    {
          field: "actions",
          headerName: "Actions",
          headerAlign: "center",
          flex: 1.25,
          renderCell: (params) => (
            <Box display="flex" justifyContent="space-evenly" margin="10px 0">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  "&:hover": {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
                onClick={() => navigate(`/customers/${params.row.id}`)} // Navigate to customer profile
              >
                <VisibilityOutlinedIcon sx={{ mr: "10px" }}/> View More
              </Button>

              {admin && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.greenAccent[600],
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: colors.greenAccent[400],
                        color: colors.greenAccent[300],
                      },
                    }}
                    onClick={() => handleEditCustomer(params.row)}
                  >
                    <EditOutlinedIcon sx={{ mr: "10px" }} /> Edit
                  </Button>
      
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.redAccent[600],
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: colors.redAccent[400],
                        color: colors.redAccent[300],
                      },
                    }}
                    onClick={() => handleOpenDialog(params.row.id)}
                  >
                    <DeleteOutlinedIcon sx={{ mr: "10px" }} /> Delete
                  </Button>
                </>
              )}
            </Box>
          ),
        },
    ] 

  return (
    <Box m="20px">
      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.gray[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "10px 0",
            float: "right",
            "&:hover": {
              backgroundColor: colors.blueAccent[800],
            },
          }}
          onClick={handleClickOpen}
        >
          <AddOutlinedIcon sx={{ mr: "10px" }} />
          Add Customer
        </Button>
          {admin && (
                  <Button
                  sx={{
                    backgroundColor: colors.redAccent[600],
                    color: colors.gray[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    margin: "10px 20px",
                    float: "right",
                    "&:hover": {
                      backgroundColor: colors.redAccent[700],
                    },
                  }}
                  onClick={() => handleOpenDialog(null)}
                >
                  <DeleteOutlinedIcon sx={{ mr: "10px" }} />
                  Delete All Customers
                </Button>
          )}

        <CustomerForm
          open={open}
          handleClose={handleClose}
          onCustomerAdded={handleAddCustomer}
          editCustomer={editCustomer}
          onCustomerEdited={handleCustomerEdited}
        />
      </Box>
      <Header title="CUSTOMERS" subtitle="Keep track of and manage your customers here" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },

          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },

          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },

          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },

          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[100]} !important`,
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.gray[100]} !important`,
            margin: "0 20px 20px 0",
            fontSize: "14px"
          },
        }}
      >
        <DataGrid 
          rows={rows}
          columns={columns}
          getRowClassName={getRowClassName}
          slots={{ 
            toolbar: GridToolbar,
          }}
           />
      </Box>


      <CustomerDeleteDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleDeleteCustomer}
        isBulkDelete={isBulkDelete}
      />
    </Box>
  );
};

export default Customers;

import React, { useState, useEffect } from 'react';
import { InputBase, Box, useTheme, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';

const SearchBar = React.forwardRef(({ onSearch }, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [query, setQuery] = useState(''); // State for the input query

    // Handle input field changes
    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    // Trigger the search action
    const handleSearch = () => {
        if (query.trim()) {
            onSearch(query); // Trigger the onSearch function passed as a prop
        }
    };

    // Listen for the Enter key
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // Notify parent to close the results (if needed)
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <InputBase
                ref={ref}
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                sx={{
                    flex: 1,
                    maxWidth: '250px',
                    backgroundColor: colors.primary[400],
                    borderRadius: 1,
                    padding: '4px 8px',
                }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Box>
    );
});

export default SearchBar;

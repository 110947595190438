import { ColorModeContext, useMode } from "./theme";
import { useState, useEffect } from "react"; 
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Sales from "./pages/sales";
import Expenses from "./pages/expenses";
import Users from "./pages/users";
import Customers from "./pages/customers";
import CustomerProfile from "./pages/customer_profile";
import Inventory from "./pages/inventory";
import Pie from "./pages/pie";
import Line from "./pages/line";
import LoginPage from "./components/auth/LoginPage";
import Profile from "./pages/profile";
import PrivateRoute from "./components/auth/PrivateRoute"; 
import { adminOnly, isAuthenticated, logout } from "./services/UserService";
import PasswordResetForm from "./components/auth/PasswordResetPage";
import { jwtDecode } from "jwt-decode";
import Bar from "./components/BarChart";
import InstallmentPage from "./pages/installments";

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const { exp } = jwtDecode(token);
        const isExpired = Date.now() >= exp * 1000;
        if (isExpired) {
          logout(); 
        }
      } catch (error) {
        console.error("Token decoding failed", error);
        logout(); 
      }
    }
  }, []); 

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password-reset" element={<PasswordResetForm />} />
          <Route path="/" element={<Navigate to={isAuthenticated() ? "/dashboard" : "/login"} />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/sales" element={<PrivateRoute><Sales /></PrivateRoute>} />
          <Route path="/expenses" element={<PrivateRoute><Expenses /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute requiredRole="ADMIN"><Users /></PrivateRoute>} />
          <Route path="/customers" element={<PrivateRoute><Customers /></PrivateRoute>} />
          <Route path="/customers/:customerId" element={<PrivateRoute><CustomerProfile /></PrivateRoute>} />
          <Route path="/installments/:salesId" element={<PrivateRoute><InstallmentPage/></PrivateRoute>}/>
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/inventory" element={<PrivateRoute><Inventory /></PrivateRoute>} />
          <Route path="/analytics" element={<PrivateRoute><Bar /></PrivateRoute>} />
          <Route path="/pie" element={<PrivateRoute><Pie /></PrivateRoute>} />
          <Route path="/line" element={<PrivateRoute><Line /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useState, useEffect } from "react";
import { getTotalMonthlySales, getRecentTransactions } from "../../services/SaleService";
import { getTotalMonthlyExpenses } from "../../services/ExpenseService";
import { getCementTotalOutQuantity } from "../../services/CementService";
import { getBlock1TotalOutQuantity } from "../../services/Block1Service";
import { getBlock2TotalOutQuantity } from "../../services/Block2Service";
import { isAdmin } from '../../services/UserService'; 


const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [ monthlySales, setMonthlySales ] = useState(0);
    const [ monthlyExpenses, setMonthlyExpenses ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const [ totalOutQuantity, setTotalOutQuantity ] = useState(0);
    const [ recentTransactions, setRecentTransactions ] = useState([]);
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    useEffect(() => {
      const fetchMonthlySales = async () => {
        try {
          const response = await getTotalMonthlySales(currentMonth, currentYear);
          setMonthlySales(response.data)
        } catch (error) {
          console.error("Error fetching monthly data: ", error);
        } finally {
          setLoading(false);
        }
      };
      fetchMonthlySales();
    }, [currentMonth, currentYear]);

    useEffect(() => {
      const fetchMonthlyExpenses = async () => {
        try {
          const response = await getTotalMonthlyExpenses(currentMonth, currentYear);
          setMonthlyExpenses(response.data);
        } catch (error) {
          console.error("Error fetching monthly data: ", error);
        } finally {
          setLoading(false);
        }
      };
      fetchMonthlyExpenses();
    }, [currentMonth, currentYear]);

    useEffect(() => {
      const fetchTotalOutQuantities = async () => {
        try {
          const cementResponse = await getCementTotalOutQuantity();
          const block1Response = await getBlock1TotalOutQuantity();
          const block2Response = await getBlock2TotalOutQuantity();

          const combinedTotal = (cementResponse.data || 0) + (block1Response.data || 0) + (block2Response.data || 0);
          setTotalOutQuantity(combinedTotal);

        } catch (error) {
          console.error("Error fetching total out quantities: ", error);
          
        } finally {
          setLoading(false);
        }
      };
      fetchTotalOutQuantities();
    }, []);

    useEffect(() => {
      const fetchRecentTransactions = async () => {
        try {
          const response = await getRecentTransactions(0, 5);
          setRecentTransactions(response.data);
        } catch (error) {
          console.error("Error fetching recent transactions: ", error); 
        }
      };
      fetchRecentTransactions();
    }, []);

  
    return (
      <Box m="-5px" sx={{scale: "0.97"}}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        </Box>
  
        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="10px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={loading ? "Loading..." : `${totalOutQuantity.toLocaleString()}`}
              subtitle="Inventory Distributed"
              progress="0.75"
              increase="+14%"
              icon={
                <Inventory2Icon
                  sx={{ color: colors.greenAccent[600], fontSize: "48px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={loading ? "Loading..." : `₦${monthlySales.toLocaleString()}`}
              subtitle="Total Monthly Sales"
              progress="0.50"
              increase="+21%"
              icon={
                <PointOfSaleIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "48px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="20+"
              subtitle="New Customers"
              progress="0.30"
              increase="+5%"
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "48px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={loading ? "Loading..." : `₦${monthlyExpenses.toLocaleString()}`}
              subtitle="Total Monthly Expenses"
              progress="0.80"
              increase="+43%"
              icon={
                <MonetizationOnIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "48px" }}
                />
              }
            />
          </Box>
  
          {/* ROW 2 */}
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            {/* Header */}

              {isAdmin() && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.gray[100]}
                  p="6px 15px"
                >
                  <Typography color={colors.gray[100]} variant="h4" fontWeight="600">
                    Owing Customers (Beta)
                  </Typography>
                  <Box>
                    <IconButton>
                      <DownloadOutlinedIcon
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: "24px",
                          mr: "10px",
                        }}
                      />
                    </IconButton>

                    <IconButton>
                      <OpenInNewOutlinedIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "24px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              )}


            {/* Table */}
            <Box p="15px">
              {isAdmin() ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p="10px"
                    borderBottom={`2px solid ${colors.primary[500]}`}
                  >
                    <Typography color={colors.gray[200]} variant="h5" fontWeight="600">
                      Name
                    </Typography>
                    <Typography color={colors.gray[200]} variant="h5" fontWeight="600">
                      Days Owing
                    </Typography>
                    <Typography color={colors.gray[200]} variant="h5" fontWeight="600">
                      Amount Owing
                    </Typography>
                  </Box>

                  {/* Example table rows */}
                  {/* {owingCustomers.map((customer, i) => (
                    <Box
                      key={`${customer.id}-${i}`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={`4px solid ${colors.primary[500]}`}
                      p="15px"
                    >
                      <Box>
                        <Typography
                          color={colors.greenAccent[500]}
                          variant="h5"
                          fontWeight="600"
                        >
                          {customer.name}
                        </Typography>
                      </Box>
                      <Box color={colors.gray[100]}>{customer.daysOwing} days</Box>
                      <Box
                        backgroundColor={colors.greenAccent[700]}
                        p="5px 10px"
                        borderRadius="4px"
                      >
                        ₦{customer.amountOwing.toLocaleString()}
                      </Box>
                    </Box>
                  ))} */}
                </>
              ) : (
                // Message for non-admin users
                <Box display="flex" justifyContent="center">
                  <Typography color={colors.gray[300]} variant="h5" fontWeight="500">
                    You do not have permission to view this information.
                  </Typography>
                </Box>
                
              )}
            </Box>
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.gray[100]}
              p="15px"
            >
              <Typography color={colors.gray[100]} variant="h5" fontWeight="600">
                Recent Sales Transactions
              </Typography>
            </Box>
            {recentTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.id}
                  </Typography>
                  <Typography color={colors.gray[100]} margin="5px 0">
                    {transaction.customerName}
                  </Typography>
                </Box>
                <Box color={colors.gray[100]}>{transaction.date}</Box>
                <Box
                  backgroundColor={colors.greenAccent[700]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  ₦{transaction.totalBalance.toLocaleString()}
                </Box>
              </Box>
            ))}
          </Box>
  
          {/* ROW 3 */}
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default Dashboard;